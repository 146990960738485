import React from "react";
import InputText from "./InputText";

const ProfileDetailsInput = ({ label,onChange,value,props }) => {
  return (
    <div className="w-full flex items-center flex-row mobile:flex-col mobile:items-start gap-0 mobile:gap-2">
      {/* Label */}
      <p className="w-[116px] mobile:w-full font-bold">{label}</p>
      {/* Value */}
      <div className="w-full ml-16 mobile:ml-0">
        <InputText onChange={onChange} value={value} {...props}/>
      </div>
    </div>
  );
};

export default ProfileDetailsInput;
