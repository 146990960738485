import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Wrapper from "./Wrapper";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

export default function TaskDetailsScreen({onOpenSidebar,isSidebarOpen}) {
  const navigate = useNavigate();
  const location = useLocation();

  const { selectedTask } = location.state || {};

  const handleStartUnity = () => {
    navigate("/legacy-taara/dashboard", {
      state: {
        userDataString: JSON.parse(localStorage.getItem("user")),
        selectedTask,
        sceneToLoad: "Roleplay",
      },
    });
  };

  return (
     <Wrapper>
          <div className="w-full flex items-center px-6 mt-6 relative">
             
                   <div className="w-full flex items-center py-4">
                        <button onClick={onOpenSidebar} className="p-2 z-50">
                {
                  !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
                
                }
                        </button>
                        <h2 className="text-lg font-bold mx-auto">{'Quick Roleplay Prep'}</h2>
                        <div className="w-6" /> {/* Spacer for alignment */}
                      </div>
              </div>
              {selectedTask &&  <div className="flex flex-col min-h-[464px] items-center justify-center mt-10 px-6 z-10">
      <div className="w-[333px] bg-[#4E327A] text-white p-6 rounded-lg shadow-lg text-center">
        <h3 className="text-lg font-bold">{selectedTask.title}</h3>
        <div className="mt-2 max-h-[550px] text-start overflow-y-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300">
          <p className="text-sm whitespace-pre-line">{selectedTask.description?.trim()}</p>
        </div>
        <div className="flex gap-3 mt-4">
          <button className="bg-[#EB4176] text-white px-4 py-2 h-[31px] rounded" onClick={handleStartUnity}>
            Start Now
          </button>
          <button
            className="border border-[#EB4176] border-solid h-[31px] text-[#EB4176] px-4 py-2 rounded-lg font-semibold text-center"
            onClick={() => navigate("/quick-prep")}
          >
            Start Quick Prep
          </button>
        </div>
      </div>
    </div>}
   
    </Wrapper>
  );
}