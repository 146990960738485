import React from 'react'
import BgTop from "../../../images/Legacy/topbg.svg";
import Bgbtlft from "../../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../../images/Legacy/btrgtbg.svg";
export default function Bgwrapper() {
  return (
    <div> 
            <img
            src={BgTop}
            alt="Decorative Element"
            className="absolute -top-4 -left-3 w-16 rotate-[20deg]"
          />
          <img
            src={Bgbtlft}
            alt="Decorative Element"
            className="absolute bottom-0 left-0 w-32"
          />
          <img
            src={Bgbtrgt}
            alt="Decorative Element"
            className="absolute bottom-0 right-0 w-32"
          /></div>
  )
}
