import React from "react";
import { useNavigate } from "react-router-dom";
import Wrapper from "./Wrapper";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

export default function QuickPrepScreen({onOpenSidebar,isSidebarOpen}) {
  const navigate = useNavigate();

  const handleStartUnity = () => {
    navigate("/legacy-taara/dashboard", {
      state: {
        userDataString: JSON.parse(localStorage.getItem("user")),
        sceneToLoad: "Roleplay", // Set scene to Roleplay
      },
    });
  };

  return (
         <Wrapper>
      <div className="w-full flex items-center px-6 mt-6 relative">
                 
                       <div className="w-full flex items-center py-4">
                            <button onClick={onOpenSidebar} className="p-2 z-50">
                    {
                      !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
                    
                    }
                            </button>
                            <h2 className="text-lg font-bold mx-auto">{'Start Roleplay'}</h2>
                            <div className="w-6" /> {/* Spacer for alignment */}
                          </div>
                  </div>
    <div className="w-[333px] bg-[#4E327A] text-white p-6 rounded-lg shadow-lg text-center mt-10 z-10">
      <h3 className="text-lg font-bold">🔅 Before You Start</h3>
      <p className="mt-2 text-sm">Hey, I know you're ready to dive right in, but take a moment to pause.</p>
      <p className="mt-2 text-sm">How does this scenario make you feel?</p>
      <p className="mt-2 text-sm">What thoughts come up as you step into this situation?</p>
      <p className="mt-2 text-sm">Notice your emotions, expectations, or even resistance—this awareness is part of the experience. Now, when you're ready, let's begin.</p>
      <h4 className="mt-4 text-md font-bold">Here Are A Few More Tips:</h4>
      <ul className="mt-2 text-sm text-left list-disc list-inside">
        <li>Set your intention—what skill are you practicing?</li>
        <li>Observe yourself—notice your thoughts, emotions & reactions.</li>
        <li>Stay present—focus on responding, not just reacting.</li>
        <li>Experiment freely—this is a safe space to test new approaches.</li>
      </ul>
      <div className="mt-4 flex justify-center gap-4">
        <button className="bg-[#EB4176] text-white px-4 py-2 h-[31px] rounded" onClick={handleStartUnity}>
          Let's Go!
        </button>
        <button
          className="border border-[#EB4176] border-solid h-[31px] text-[#EB4176] px-4 py-2 rounded-lg font-semibold text-center"
          onClick={() => navigate(-1)}
        >
          Talk to Team
        </button>
      </div>
    </div>
    </Wrapper>
  );
}