import React from "react";
import BgTop from "../../images/Legacy/topbg.svg";
import Bgbtlft from "../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../images/Legacy/btrgtbg.svg";
import { ChevronLeft, BookOpen, FileText,Youtube } from "lucide-react";
import { useNavigate } from "react-router-dom";
import Bgwrapper from "./components/Bgwrapper";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

export default function BillingPage({onOpenSidebar,isSidebarOpen}) {
    const navigate = useNavigate(); // Use useNavigate for navigation
  
  return (
    <div className="flex flex-col items-center w-full h-full px-6 py-4 bg-white  rounded-lg z-50 gap-[20px] ">

                      <button onClick={onOpenSidebar} className="absolute left-0 p-2 mt-[50px] z-50">
              {
                !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
              
              }
              </button>
      <h2 className="text-[20px] font-bold text-center text-pink-600 mb-2 mt-[120px]">
        Thanks For Giving Us The Change To Help You Grow
      </h2>
      
      <h3 className="text-[20px] font-audiowide text-gray-800 mb-4">
        Subscription Details
      </h3>
      
      <div className="w-full max-w-sm text-sm text-gray-700 space-y-2">
        <div className="flex justify-between">
          <span className="font-nohemi font-bold text-[16px]">Current Plan:</span>
          <span >Grow Potential</span>
        </div>
        <div className="flex justify-between">
          <span className="font-nohemi font-bold text-[16px]">Subscription Status:</span>
          <span className="text-start">Active</span>
        </div>
        <div className="flex justify-between">
          <span className="font-nohemi font-bold text-[16px]">Renews On:</span>
          <span>4. December 2024</span>
        </div>
      </div>
      
      <button className="w-full max-w-sm bg-[#533884] text-white py-2 mt-4 rounded-lg font-nohemi text-center">
        Manage Billing
      </button>
      
      <div className="w-full max-w-sm text-sm text-gray-700 space-y-2 mt-4">
        <div className="flex justify-between">
          <span className="font-medium text-[16px]">Coaching conversations remaining:</span>
          <span>2</span>
        </div>
        <div className="flex justify-between">
          <span className="font-medium text-[16px]">Scenarios remaining:</span>
          <span>3/5</span>
        </div>
      </div>
      
      <div className="flex gap-4 mt-4 w-full max-w-sm">
        <button className="flex-1 bg-[#533884] text-white py-2 rounded-lg font-nohemi text-center">
          Change Plan
        </button>
        <button className="flex-1 border bg-[#EB4176] text-white py-2 rounded-lg font-nohemi text-center">
          Get More Credits
        </button>
      </div>
       <Bgwrapper />
    </div>
  );
}
