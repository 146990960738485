import React, { useState } from "react";
import { ChevronLeft } from "lucide-react";
import { Download, Link } from "lucide-react";
import LazyVideo from "../../components/LazyVideo/LazyVideo";

export default function ResourcesScreen({ onBack }) {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [activeTab, setActiveTab] = useState("videos");

  // Sample Static Data
  const resources = [
    {
      title: "Boundaries",
      videos: [
        {
          title: "Setting Work Boundaries",
          description:
            "Learn how to establish healthy work boundaries for better productivity.",
          thumbnail:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Salary+Talk+2.png",
          video:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Salary+Talk+Video+2.mp4",
        },
        {
          title: "Work-Life Balance Strategies",
          description: "Improve your well-being with work-life balance tips.",
          thumbnail:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Salary+Talk+1.png",
          video:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Salary+Talk+Video+1.mp4",
        },
        {
          title: "Work-Life Balance Strategies",
          description: "Improve your well-being with work-life balance tips.",
          thumbnail:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Salary+Talk+3.png",
          video:
            "https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Salary+Talk+Video+3.mp4",
        },
      ],
    },
  ];

  const guides = [
    {
      title: "Test your Boundaries",
      description: "Personal Boundary Assessment Worksheet",
    },
    {
      title: "Test your Boundaries",
      description: "Personal Boundary Assessment Worksheet",
    },
    {
      title: "Test your Boundaries",
      description: "Personal Boundary Assessment Worksheet",
    },
  ];

  return (
    <div className="flex flex-col items-center w-full h-full px-4 mt-4">
      {/* 🔹 Back Button & Title */}
      <div className="w-full flex items-center py-4">
        <button
          onClick={() => (selectedVideo ? setSelectedVideo(null) : onBack())}
          className="p-2 z-50"
        >
          <ChevronLeft size={24} className="text-gray-700" />
        </button>
        <h2 className="text-lg font-bold mx-auto">
          {selectedVideo ? selectedVideo.title : "Resources"}
        </h2>
        <div className="w-6" /> {/* Spacer for alignment */}
      </div>

      {/* 🔹 Show Video Details if a video is selected */}
      {selectedVideo ? (
        <>
          <p className="text-center text-gray-600 text-sm px-6 mt-2">
            {selectedVideo.description}
          </p>

          {/* Video Image (Replace with actual video player if needed) */}
          <div className="w-full mt-4">
            {/* <LazyVideo
              className="resourcevideo"
              src={'https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Salary+Talk+Video+3.mp4'}
              poster={selectedVideo.thumbnail}
            /> */}
              <LazyVideo
          className="TaaraIntroVideoAreaMobile"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
        />
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col  gap-4 mt-6 w-full max-w-xs">
            <button className="h-[67px] bg-[#EB4176] text-white py-3 rounded-lg font-semibold text-center">
              Apply in Roleplay
            </button>
            <button className="border border-[#EB4176] border-solid h-[67px] text-pink-500 py-3 rounded-lg font-semibold text-center">
              Talk to Taara
            </button>
          </div>
        </>
      ) : (
        <>
          {/* 🔹 Tabs Section */}
          <div className="flex w-[292px] h-[36px] bg-gray-200 rounded-full mt-4 cursor-pointer">
            <button
              className={`flex-1 py-2 rounded-full text-sm font-semibold text-center ${
                activeTab === "videos"
                  ? "bg-[#EB4176] text-white"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab("videos")}
            >
              Videos
            </button>
            <button
              className={`flex-1 py-2 rounded-full text-sm font-semibold text-center ${
                activeTab === "guides"
                  ? "bg-[#EB4176] text-white"
                  : "text-gray-600"
              }`}
              onClick={() => setActiveTab("guides")}
            >
              Guides & Links
            </button>
          </div>

          {/* 🔹 Content Based on Selected Tab */}
          <div className="mt-4 w-full">
            {activeTab === "videos" ? (
              <div className="flex flex-col gap-4">
                {resources[0].videos.map((video, index) => (
                  <div
                    key={index}
                    className="w-full cursor-pointer z-50"
                    onClick={() => setSelectedVideo(video)}
                  >
                    <LazyVideo
                      className="TaaraIntroVideoAreaMobile"
                      src={video.video}
                      poster={video.thumbnail}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-4 w-full">
                {activeTab === "guides" && (
                  <div className="flex flex-col gap-3 w-full">
                    {guides.map((guide, index) => (
                      <div
                        key={index}
                        className="bg-white border border-gray-300 rounded-lg p-4 flex flex-col gap-2"
                      >
                        <p className="text-base font-semibold text-black">
                          {guide.title}
                        </p>
                        <p className="text-sm text-gray-500">
                          {guide.description}
                        </p>
                        <div className="flex gap-2 mt-2">
                          <button className="flex gap-2 bg-[#533884] text-white text-xs px-3 py-1 rounded flex items-center gap-1 cursor-pointer">
                            <Download className="w-4 h-4 pr-1" />
                            Download PDF / <Link className="w-4 h-4 pr-1" />
                            Open Link
                          </button>
                      
                        </div>
                      </div>
                    ))}
                    <div className="flex flex-col  gap-4 mt-6 w-full  z-50">
                      <button className="h-[67px] bg-[#EB4176]  text-white py-3 rounded-lg font-semibold text-center">
                        Apply in Roleplay
                      </button>
                      <button className="border border-[#EB4176] border-solid h-[67px] text-pink-500 py-3 rounded-lg font-semibold text-center">
                        Talk to Taara
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
