import React, { useEffect } from "react";
import PrimaryHeading from "../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../components/button/PrimaryButton";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import Markdown from "../../components/Markdown/Markdown";
import { Link } from "react-router-dom";
import { CheckCircle } from "lucide-react";
import LazyImage from "../../components/LazyImage/LazyImage";
import TextInput from "../../components/formInputs/TextInput";
import { useForm } from "react-hook-form";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import roundLogo from "../../images/round-logo.svg";
import newsletter from "../../images/newsletter.svg";
import newstick from "../../images/newstick.svg";
const features = [
  "Exclusive Events & Workshops",
  "Career & Confidence Tips",
  "Voices From The Community",
];
const NewsLetter = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <NonProtectedLayout showFooter={true}>
      <div className="flex flex-col items-center px-[254px] mobile:px-0 mt-12">
        <div className="flex flex-col gap-[21px] items-center px-12 mobile:px-4 mb-24 w-[967px] mobile:w-full">
          <PrimaryHeading
            heading="JOIN THE TAARA QUEST COMMUNITY NEWSLETTER"
            className="text-center w-[80%]"
          />
          <div className="w-[537px] mobile:w-[80%] m-auto flex justify-center text-center">
            Get Exclusive insights, realworld roleplay challenges and expert-{" "}
            <br />
            backed strategies to grow your confidence and communication skills
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-6 w-full">
          <div className="bg-white rounded-2xl shadow-lg p-6 h-60 w-full flex items-center">
            <ul className="space-y-4">
              {features.map((feature, index) => (
                <li key={index} className="flex items-center space-x-3">
                  <img src={newstick} alt="third-section-image" bg="none" />
                  <span className="text-gray-800 font-medium">{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          <LazyImage
            src={newsletter}
            alt="third-section-image"
            bg="none"
            className="h-60 w-full rounded-2xl object-cover"
          />
        </div>

        <div className=" mobile:px-[0px] py-16 text-white">
          <form className="flex flex-col gap-9 mobile:gap-6 bg-primaryBlue relative rounded-[20px] px-[200px] lg:px-[150px] md:px-[100px] mobile:px-10 pt-[153px] mobile:pt-16 pb-7">
            <h2 className="text-center text-[32px] mobile:text-[24px] font-audioWide">
              STAY CONNECTED & KEEP GROWING
            </h2>
            <div className="flex flex-col gap-6">
              <TextInput
                type="text"
                name="fullName"
                placeholder="Full Name"
                register={register}
                errors={errors}
                isRequired={true}
              />
              <TextInput
                type="email"
                name="email"
                placeholder="Email Address"
                register={register}
                errors={errors}
                isRequired={true}
                validateInput
                pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
              />
            </div>
            <div className="flex flex-col gap-6 mobile:gap-3 items-center justify-center">
              <button
                type="submit"
                onClick={handleSubmit}
                className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] w-[130px] px-5 hover:scale-110 transition-transform disabled:bg-opacity-70 disabled:cursor-not-allowed disabled:scale-100"
              >
                Join Now
              </button>
              <div className="flex flex-col gap-2">
                <p className="text-md text-sm text-center">
                  No spam, just insights and stories to help you grow.
                  Unsubscribe anytime.
                </p>
              </div>
            </div>
            <div className="w-[180px] h-[180px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
              <LazyImageObjectFit
                src={roundLogo}
                alt="Taara Quest Logo"
                className="object-cover"
                bg="none"
              />
            </div>
          </form>
        </div>
      </div>
    </NonProtectedLayout>
  );
};

export default NewsLetter;
