import React, { useState } from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import ProfileDetails from "../../../components/Profile/ProfileDetails";
import PrimaryButton from "../../../components/button/PrimaryButton";
import ProfileDetailsInput from "../../../components/Profile/ProfileDetailsInput";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { updateUser } from "../../../store/authSlice";
import toast, { Toaster } from "react-hot-toast";

const URL = `${process.env.REACT_APP_BACKEND}/auth/profile`;

const Profile = () => {
  const [editDetails, setEditDetails] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const [name, setName] = useState(user?.name || "");
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // const onSubmit = (data) => console.log(data);
  const { token } = useSelector((state) => state.auth);

  const onSubmit = async () => {
    try {
      const response = await axios.patch(
        URL,
        { name, email: user?.email },
        {
          headers: {
            authtoken: token, // Use the token stored in Redux or Context
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        dispatch(updateUser({ ...user, name })); // Update Redux store
        toast.success("User Name updated Successfully");
        setEditDetails(false); // Exit edit mode
      }
    } catch (error) {
      toast.error("User Name update Failed");
      console.error("Error updating profile:", error);
    }
  };
  const handleToggleEditDetails = () => setEditDetails((prev) => !prev);
  const handleToggleEditPassword = () => setEditPassword((prev) => !prev);
  const handleCancel = () => {
    setEditDetails(false);
    setName(user?.name);
  };
  return (
    <ProtectedRoutesLayout>
      <div className="px-[258px] pt-0 mobile:pt-8 mobile:px-4 flex flex-col gap-24 mobile:gap-12 pb-4 mobile:pb-12">
        <div className="flex flex-col gap-16 mobile:gap-6">
          {!editDetails ? (
            <div className="flex flex-col">
              <ProfileDetails label="Name" value={user?.name} />
              <ProfileDetails label="Email" value={user?.email} />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-3 mobile:gap-4"
            >
              <ProfileDetailsInput
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              {errors.name && (
                <span className="text-red-500">Name is required</span>
              )}
            </form>
          )}
          <div style={{ display: "flex", gap: "25px" }}>
            <PrimaryButton
              text={editDetails ? "Save" : "Edit"}
              onClick={editDetails ? onSubmit : handleToggleEditDetails}
            />
            {editDetails && (
              <PrimaryButton
                text={"Cancel"}
                onClick={handleCancel}
                className="bg-black"
              />
            )}
          </div>
        </div>

        <div className="flex flex-col gap-16 mobile:gap-6">
          {!editPassword ? (
            <div className="flex flex-col">
              <ProfileDetails label="Password" value="***********" />
            </div>
          ) : (
            <form className="flex flex-col gap-3">
              <ProfileDetailsInput label="Current Password" />
              <ProfileDetailsInput label="Repeat current Password" />
              <ProfileDetailsInput label="New Password" />
            </form>
          )}
          <PrimaryButton
            text="Change Password"
            onClick={handleToggleEditPassword}
          />
        </div>
        <Toaster />
      </div>
    </ProtectedRoutesLayout>
  );
};

export default Profile;
