import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess, logout } from "../store/authSlice";
import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ element: Component, ...otherProps }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("user");

    if (!storedToken || !storedUser) {
      dispatch(logout());
      navigate("/login");
    } else {
      if (!user)
        dispatch(
          loginSuccess({ token: storedToken, user: JSON.parse(storedUser) })
        );
    }
  }, []);
  return isAuthenticated ? Component : <Navigate to="/login" />;};

export default ProtectedRoute;
