import React, { useState } from "react";
import SidebarItem from "./SidebarItem";
import { CircleX } from "lucide-react";
import Telegram from "../../../images/Legacy/telegram.svg";
import { useNavigate } from "react-router-dom";
import Profileimg from '../../../images/Legacy/Group.svg';
import Logoutlogo from '../../../images/Legacy/cuida_logout-outline.svg';
import support from '../../../images/Legacy/support.svg';
import resource from '../../../images/Legacy/resource.svg';
import scenario from '../../../images/Legacy/scenario.svg';
import home from '../../../images/Legacy/home.svg';
import billing from '../../../images/Legacy/billing.svg';
import Isupport from '../../../images/Legacy/Isupport.svg';
import Iresource from '../../../images/Legacy/Iresource.svg';
import Iscenario from '../../../images/Legacy/Iscenario.svg';
import Ihome from '../../../images/Legacy/Ihome.svg';
import Ibilling from '../../../images/Legacy/Ibilling.svg';
import Iprofile from '../../../images/Legacy/Iprofile.svg';

export default function Sidebar({ isOpen, onClose }) {
  const [showFirstPopup, setShowFirstPopup] = useState(false);
  const [activeItem, setActiveItem] = useState("Home");
  const navigate = useNavigate();

  const handleJoin = () => {
    const telegramAppLink = "https://t.me/+3nUudJtUEeMzNDE6"; // Replace with your Telegram username/channel
    const telegramWebLink = "https://t.me/+3nUudJtUEeMzNDE6"; // Web fallback
    const appStoreLink = "https://apps.apple.com/app/telegram-messenger/id686449807";
    const playStoreLink = "https://play.google.com/store/apps/details?id=org.telegram.messenger";

    // Attempt to open Telegram app
    window.location.href = telegramAppLink;
    setShowFirstPopup(false)
    // If the app is not installed, redirect to the store after a delay
    setTimeout(() => {
      if (navigator.userAgent.match(/iPhone|iPad|iPod/)) {
        window.open(appStoreLink, "_blank");
      } else if (navigator.userAgent.match(/Android/)) {
        window.open(playStoreLink, "_blank");
      } else {
        window.open(telegramWebLink, "_blank"); // Default to web
      }
    }, 1000);
  };

  return (
    <>
      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 w-[60%] h-full bg-[#FFFFFF] text-white shadow-lg flex flex-col p-3 transition-transform z-50 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
      

        {/* Telegram Button */}
        <button
          className="flex gap-2 items-center text-black hover:text-[white] hover:bg-[#3e2a5f] p-2 rounded"
          onClick={() => setShowFirstPopup(true)}
        >
          <div className="bg-white rounded-full p-1">
            <img src={Telegram} alt="User" className="rounded-full w-6 h-6" />
          </div>
          <p className="text-[10px]">Join Our Community</p>
        </button>

        {/* Profile Section */}
        <div className="flex items-center gap-3 my-4">
          <img src={Profileimg} alt="User" className="w-10 h-10 rounded-full" />
          <div>
            <p className="text-[8px] text-[#3e2a5f] font-thin">PRODUCT DESIGNER</p>
            <p className="font-sans text-[10px] text-[#3e2a5f] font-extrabold text-start">
              Sunaina Mir
            </p>
          </div>
        </div>

        {/* Navigation Items */}
        <nav className="flex flex-col gap-2">
          <SidebarItem
            text="Home"
            icon={activeItem === "Home" ? <img src={home} /> : <img src={Ihome} />}
            active={activeItem === "Home"}
            onClick={() => {
              setActiveItem("Home");
              navigate("/legacy-taara");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Scenarios"
            icon={activeItem === "Scenarios" ? <img src={scenario} /> : <img src={Iscenario} />}
            active={activeItem === "Scenarios"}
            onClick={() => {
              setActiveItem("Scenarios");
              navigate("/legacy-taara/roleplay-scenario");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Resources"
            icon={activeItem === "Resources" ? <img src={resource} /> : <img src={Iresource} />}
            active={activeItem === "Resources"}
            onClick={() => {
              setActiveItem("Resources");
              navigate("/legacy-taara/resources-screen");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Profile"
            icon={activeItem === "Profile" ? <img src={Logoutlogo} /> : <img src={Iprofile} />}
            active={activeItem === "Profile"}
            onClick={() => {
              setActiveItem("Profile");
              navigate("/profile-page");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Support"
            icon={activeItem === "Support" ? <img src={support} /> : <img src={Isupport} />}
            active={activeItem === "Support"}
            onClick={() => {
              setActiveItem("Support");
              navigate("/support");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Billing"
            icon={activeItem === "Billing" ? <img src={billing} /> : <img src={Ibilling} />}
            active={activeItem === "Billing"}
            inactive={true}
            onClick={() => {
              setActiveItem("Billing");
              navigate("/billing-page");
              onClose(); // Close sidebar after navigation
            }}
          />
          <SidebarItem
            text="Log Out"
            icon={<img src={Logoutlogo} />}
            active={activeItem === "Log Out"}
            onClick={() => {
              navigate("/");
              onClose(); // Close sidebar after navigation
            }}
          />
        </nav>
      </div>

      {/* Telegram Popup */}
      {showFirstPopup && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#EB4176] p-4 rounded shadow-md w-[224px] h-[194px] z-[1000]">
          <CircleX
            onClick={() => setShowFirstPopup(false)}
            className="absolute top-1 right-1 w-[16px] h-[16px] cursor-pointer"
          />
          <p className="text-white text-[18px] font-bold text-center mt-3">
            Join Our Community?
          </p>
          <p className="text-white text-[13px] text-center">
            Hop Over To Telegram To Connect With Other Trabilizing Women
          </p>
          <div className="flex justify-center gap-3 mt-3">
            <button
              className="bg-[#4E327A] text-white p-1 rounded text-[14px] w-[56px] text-center"
              onClick={handleJoin}
            >
              Join
            </button>
            <button
              className="border border-[#4E327A] border-solid p-1 rounded text-[14px] text-[#4E327A] w-[56px] text-center"
              onClick={() => setShowFirstPopup(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
}