import React, { Suspense, useEffect, useRef, useState } from "react";
import "./Landing.css";

import roundLogo from "../../images/round-logo.svg";
import { useNavigate } from "react-router-dom";

import ReactCardFlip from "react-card-flip";
import LazyVideo from "../../components/LazyVideo/LazyVideo";
import LaunchCountdown from "../../components/LaunchCountdown/LaunchCountdown";
import { encryptData } from "../../utility/encryption";
import axios from "axios";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import LazyImage from "../../components/LazyImage/LazyImage";
import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
// import questBackImage from "../../images/landing/quests/quest1bg.webp";
import LandingHeroVideo from "../../components/ambassadors/LandingHeroVideo/LandingHeroVideo";
import Header from "../../components/Header/Header";
import TaaraWorldFeatures from "../../components/Landing/TaaraWorldFeatures/TaaraWorldFeatures";

const parnerLogos = [
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/1.png",
    alt: "logo1",
  },
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/2.png",
    alt: "logo2",
  },
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/3.png",
    alt: "logo3",
  },
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/4.png",
    alt: "logo4",
  },
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/5.png",
    alt: "logo5",
  },
  {
    logo: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/partners/6.png",
    alt: "logo6",
  },
];

const Landing = () => {
  const navigate = useNavigate();
  const testimonialSignupRef = useRef(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [reactCardState, setReactCardState] = useState({
    quest1: {
      header: "Quest I",
      title: "CONFIDENCE",
      decribeText:
        "Discover what's holding you back and learn to grow your confidence and self-worth.",
      flipped: false,
      quest: "quest1",
    },
    quest2: {
      header: "Quest II",
      title: "EFFECTIVE FEEDBACK",
      decribeText:
        "Learn how to give and receive feedback effectively without feeling troubled.",
      flipped: false,
      quest: "quest2",
    },
    quest3: {
      header: "Quest III",
      title: "DIFFICULT CONVERSATION",
      decribeText:
        "Master the art of discussing money and confidently ask for more.",
      flipped: false,
      quest: "quest3",
    },
  });

  const handleSignup = () => {
    if (isLoading || name === "" || email === "") {
      return;
    } else {
      setIsLoading(true);
      setErrorMessage("");
      const URL = `${process.env.REACT_APP_BACKEND}/waitlist/join`;
      const payload = {
        name,
        email,
      };

      const token = encryptData(JSON.stringify(payload));
      axios
        .post(URL, { token })
        .then((response) => {
          setErrorMessage(response.data.message);
          // console.log(errorMessage);
          setIsLoading(false);
          setName("");
          setEmail("");
          setPassword("");
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
          // console.log(errorMessage);
          setIsLoading(false);
          setName("");
          setEmail("");
          setPassword("");
        });
    }
  };

  const scrollToTestimonialSignup = () => {
    if (testimonialSignupRef.current) {
      testimonialSignupRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const shouldScrollToSignup = localStorage.getItem("shouldScrollToSignup");
    if (shouldScrollToSignup) {
      localStorage.removeItem("shouldScrollToSignup");
      scrollToTestimonialSignup();
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, []);

  const toggleFlip = (cardKey) => {
    setReactCardState((prevState) => ({
      ...prevState,
      [cardKey]: {
        ...prevState[cardKey],
        flipped: !prevState[cardKey].flipped,
      },
    }));
  };

  const handleMouseEnter = (cardKey) => toggleFlip(cardKey);
  const handleMouseLeave = (cardKey) => toggleFlip(cardKey);
  const handleElasticSignup = () => {
    if (isLoading || name === "" || email === "") {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const URL = "https://api.elasticemail.com/v2/contact/add"; // Elastic Email API URL
    const payload = {
      publicAccountID: process.env.REACT_APP_ELASTIC_PUBLIC_ID, // Your Elastic Email public account ID
      email: email,
      name: name,
      consent: true,
    };

    const token = encryptData(JSON.stringify(payload)); // Encrypt the payload

    axios
      .post(URL, { token })
      .then((response) => {
        setErrorMessage("You have been added to the waitlist!");
        setIsLoading(false);
        setName("");
        setEmail("");
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message || "Something went wrong!"
        );
        setIsLoading(false);
      });
  };

  return (
    <NonProtectedLayout>
      <Header
        title="Professional Development & Game-Based Learning | Taara Quest"
        description="Unlock your potential with Taara Quest's professional development plans.
Our game-based learning boosts leadership and communication skills
through interactive training."
        keywords="professional development plan, game-based learning, leadership and
communication training, professional development course"
      />
      <div className="landingPageContainer">
        <div className="landingPageComponents">
          <LandingHeroVideo
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/landing-page-video-1.mp4"
            onClick={scrollToTestimonialSignup}
            heading="GROW INTO WHO YOU'RE MEANT TO BE."
            ctaText="Get Early Access"
          />
          <div className="brandBannerList">
            {parnerLogos.map((parnerLogo, idx) => (
              <div className="brandBanner" key={idx}>
                <LazyImage
                  src={parnerLogo.logo}
                  alt={parnerLogo.alt}
                  bg="none"
                />
              </div>
            ))}
          </div>
          <div className="secondWaitlistArea">
            <div className="secondWaitlistAreaContent">
              <span>Your</span> interactive space <span>to</span> practice (and
              mess up) <span>those</span> real-world, hard-to-have
              <span> conversations before they happen.</span>
            </div>
            <div
              className="secondWaitlistAreaButton"
              onClick={scrollToTestimonialSignup}
            >
              Get Early Access
            </div>
          </div>
          <div className="graphicalBackgroundArea">
            <div className="topGraphicalArea">
              <div className="topGraphicalAreaText">
                YOU WONT BE ALONE <span>in your journey!</span>
              </div>
              <div className="topGraphicalAreaParagraph">
                With Taara's growth oriented feedback and encouragement, you'll
                unlearn self-doubt <span>quest-by-quest</span>
              </div>
            </div>
            <div className="bottomGraphicalArea">
              <div className="leftGraphicsArea">
                <div className="leftGraphicsAreaHeader">
                  Through 6 immersive <br />
                  roleplay quests, discover the <br /> layers of your potential
                </div>
                <div className="leftGraphicsAreaContent">
                  <span>No Judgement.</span>
                  <span>No Awkwardness.</span>
                </div>
                <div
                  className="leftGraphicsAreaButton"
                  onClick={scrollToTestimonialSignup}
                >
                  Get Early Access
                </div>
              </div>
              <div className="rightGraphicsArea">
                <LazyImage
                  src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/TaaraBack.webp"
                  alt={"right-graphics-area"}
                  width="100%"
                  height="100%"
                  bg="none"
                />
                {/* <img src={rightGraphicsImage} alt="right-graphics-area" loading="lazy" width="100%" height="100%" /> */}
              </div>
            </div>
          </div>
          {/* <Suspense fallback={<div>Loading...</div>}>
            <LaunchCountdown action={scrollToTestimonialSignup} />
          </Suspense> */}
          <div className="unvelingArea">
            <div className="leftUnvelingArea">
              UNVEIL THE WORLD
              <span className="blackLeftUnvelingAreaText">of Taara Quest!</span>
            </div>
            <div className="rightUnveilingArea">
              <div className="rightUnveilingAreaText">
                Taara Quest is an innovative roleplaying game meticulously
                designed to arm women in the workplace with critical leadership
                skills, mindset shifting techniques, and actionable
                communication tools.
              </div>
            </div>
          </div>
          <div className="stepCards">
            {Object.keys(reactCardState)?.map((key, idx) => {
              const quest = reactCardState[key];
              return (
                <div
                  key={idx}
                  className="stepCard"
                  onMouseEnter={() => handleMouseEnter(quest.quest)}
                  onMouseLeave={() => handleMouseLeave(quest.quest)}
                >
                  <ReactCardFlip
                    isFlipped={quest.flipped}
                    flipDirection="horizontal"
                  >
                    <div className="frontCard">
                      <div className="stepCardContainer">
                        <div className="topStepCard">
                          <div className="topStepCardIcon">
                            <i
                              className="fa-solid fa-circle-arrow-down"
                              aria-hidden={true}
                            ></i>
                          </div>
                        </div>

                        <div className="bottomStepCard">
                          <div className="bottomStepCardCount">
                            {quest.header}
                          </div>
                          <div className="bottomStepCardTitle">
                            {quest.title}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`backCard backCardQuest${idx + 1}`}>
                      <div className="stepCardContainerFlipped">
                        <div className="topStepCard">
                          <div className="topStepCardText">
                            {quest.decribeText}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ReactCardFlip>
                </div>
              );
            })}
          </div>
          <div className="moreQuests">
            <div className="moreQuestsDiv">More Quests Coming Soon</div>
          </div>
          <div className="guideArea">
            <div className="guideAreaWrapper">
              <div className="leftGuideArea">
                <div className="leftGuideAreaTitle">
                  <div className="leftGuideAreaTitleTop">MEET YOUR</div>
                  <div className="leftGuideAreaTitleBottom">Guide Taara!</div>
                </div>
                <div className="rightGuideAreaForMobile"></div>
                <div className="leftGuideAreaContent">
                  <p>
                    Taara believes in the power of unity over division,
                    compassion over control. Her mission transcends mere
                    resistance against oppressive systems;
                  </p>
                  <p>
                    It's about nurturing an environment where every individual
                    can flourish.
                  </p>
                  <p>
                    She imagines a world where workplaces become sancturies of
                    opportunity, free from the shadows of inequality.
                  </p>
                </div>
                <div
                  className="leftGuideAreaButton"
                  onClick={() => {
                    navigate("/taara");
                  }}
                >
                  Learn More About Taara
                </div>
              </div>
            </div>
            <div className="rightGuideArea">
              <LazyImage
                src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/landing/TaaraFront.webp"
                alt="taara-front"
                width="100%"
                height="100%"
                bg="none"
              />
            </div>
          </div>
          <div className="taaraWorldArea">
            <div className="taaraWorldHeading">
              Taara's world Isn't Just About{" "}
              <span>Practicing Hard Conversations.</span> It's About...
            </div>
            <TaaraWorldFeatures />
          </div>
          {/* <video
            className="videoContainer"
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Platform+Intro+Video.mp4"
            poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Product+Video+Thumbnail_Landingpage+second+video.png"
            controls
            preload="none"
          ></video> */}
          <LazyVideo
            className="videoContainer"
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Platform+Intro+Video.mp4"
            poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Product+Video+Thumbnail_Landingpage+second+video.png"
          />
          {/* <LaunchCountdown action={scrollToTestimonialSignup} /> */}
          <div className="testimonialSignup relative">
            <div className="testimonialContainer">
              <div className="testimonialHeading">
                WHAT PEOPLE ARE <br />
                <span>Saying About Taara Quest!</span>
              </div>
              <div className="TestimonialCarouselContainer">
                <Suspense fallback={<div>Loading...</div>}>
                  <TestimonialCarousel />
                </Suspense>
              </div>
              <div
                className="signupContainer"
                ref={testimonialSignupRef}
                id="signup-form"
              >
                <div className="taaraRoundLogo">
                  <LazyImage
                    src={roundLogo}
                    alt="Taara Quest Logo"
                    width="150px"
                    height="150px"
                    bg="none"
                  />
                </div>
                <h2>SIGN UP</h2>
                <div className="formComponents">
                  <input
                    type="text"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => {
                      if (!isLoading) {
                        setName(e.target.value);
                      }
                    }}
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => {
                      if (!isLoading) {
                        setEmail(e.target.value);
                      }
                    }}
                  />
                  {errorMessage ? (
                    <>
                      <div className="errorMessage">{errorMessage}</div>
                    </>
                  ) : (
                    <></>
                  )}
                  <button
                    type="submit"
                    onClick={handleElasticSignup}
                    disabled={isLoading}
                  >
                    {isLoading ? "Processing..." : "Get Early Access"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[480px] mobile:mt-[400px]"></div>
        </div>
      </div>
    </NonProtectedLayout>
  );
};

export default Landing;
