// HomeScreen.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Button from "./components/Button";
import TaaraLogo from "../../images/logo.svg";
import Menu from "../../images/Legacy/menu.svg";
import ResourcesScreen from "./Resources";
import "./LegacyStyle.css";

export default function HomeScreen({ onStartRoleplay, onOpenSidebar,isSidebarOpen }) {
  const navigate = useNavigate(); // Use useNavigate for navigation

  const handleTalkToTaara = () => {
    navigate("/legacy-taara/dashboard", {
      state: {
        userDataString: JSON.parse(localStorage.getItem('user')),
        sceneToLoad: "Holodeck", // Set scene to Holodeck
      },
    });
  };



  return (
    <div className="flex flex-col items-center w-full h-full">
      {/* Top Section */}
      <div className="w-full flex justify-center relative pt-[80px]">
        {!isSidebarOpen &&   <img
          src={Menu}
          alt="Menu Icon"
          className="absolute left-12 cursor-pointer z-50"
          onClick={onOpenSidebar}
        />}
      
        <img
          src={TaaraLogo}
          alt="Taara Quest Logo"
          className="w-[200px] h-[104px]"
        />
      </div>

      {/* Button Group */}
      <div className="flex flex-col gap-8 mt-16 w-[333px]">
        <Button text="START ROLEPLAY" onClick={()=>navigate('/legacy-taara/roleplay-scenario')} />
        <Button text="TALK TO TAARA" onClick={handleTalkToTaara} /> {/* Navigate to Unity */}
        <Button text="SEE RESOURCES" onClick={()=>navigate('/legacy-taara/resources-screen')} />
      </div>

      <div className="font-nohemi text-[18px] mt-[60px] color-[#0000] font-[600] cursor-pointer" onClick={()=>navigate('/tutorial')}>SEE TUTORIAL</div>
    </div>
  );
}