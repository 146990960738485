import { createSlice } from "@reduxjs/toolkit";

// Helper function to load state from localStorage
const loadStateFromLocalStorage = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  return { token, user };
};

const initialState = {
  token: null,
  isAuthenticated: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const { token, user } = action.payload;
      state.token = token;
      state.user = user;
      state.isAuthenticated = true;
      localStorage.setItem("token", token); // Persist token
      localStorage.setItem("user", JSON.stringify(user)); // Persist user
    },
    logout: (state) => {
      state.token = null;
      state.isAuthenticated = false;
      state.user = null;
      localStorage.removeItem("token"); // Clear token from storage
      localStorage.removeItem("user");
    },
    updateUser: (state, action) => {
      // Merge existing user with updated details from the action payload
      state.user = { ...state.user, ...action.payload };
      localStorage.setItem("user", JSON.stringify(state.user)); // Update persisted user data
    },
    rehydrateAuthState: (state) => {
      const { token, user } = loadStateFromLocalStorage();
      if (token && user) {
        state.token = token;
        state.user = user;
        state.isAuthenticated = true;
      }
    },
  },
});

export const { loginSuccess, logout, updateUser, rehydrateAuthState } = authSlice.actions;
export default authSlice.reducer;