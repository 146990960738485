// components/Task.js
import React from "react";
import ArrowRight from "../../../images/Legacy/arrowRgt.svg";

export default function Task({ task, onClick }) {
  return (
    <div
      className="bg-[#4E327A] text-white p-4 rounded-lg shadow-md flex flex-col justify-between relative cursor-pointer hover:shadow-lg"
      onClick={onClick}
    >
      <h3 className="text-[15px] font-nohemi uppercase">{task.title}</h3>
      <p className="text-[12px] font-nohemi text-[#BBBBBB] mt-2 mb-2">
       {task.subtitle}
      </p>
      <img src={ArrowRight} className="h-[32px] w-[32px]" alt="rgt" />
    </div>
  );
}