import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_BACKEND_STAGING;

// Fetch all scenarios
const fetchAllScenarios = async () => {
  const URL = `${API_BASE_URL}/scenario`;
  return axios.get(URL);
};

// Fetch scenarios by quest ID
const fetchScenariosByQuestId = async (questId) => {
  const URL = `${API_BASE_URL}/scenario/quest/${questId}`;
  return axios.get(URL);
};

// Create a new scenario
const createScenario = async (payload, headers) => {
  const URL = `${API_BASE_URL}/scenario`;
  return axios.post(URL, payload, { headers });
};

// Update an existing scenario by ID
const updateScenario = async (scenarioId, payload, headers) => {
  const URL = `${API_BASE_URL}/scenario/admin/${scenarioId}`;
  return axios.put(URL, payload, { headers });
};

// Delete a scenario by ID
const deleteScenario = async (scenarioId, headers) => {
  const URL = `${API_BASE_URL}/scenario/admin/${scenarioId}`;
  return axios.delete(URL, { headers });
};

export { fetchAllScenarios, fetchScenariosByQuestId, createScenario, updateScenario, deleteScenario };
