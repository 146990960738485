import { ChevronLeft, BookOpen, FileText,Youtube } from "lucide-react";
import React, { useState } from "react";
import ResourceDetailScreen from "./ResourceDetailScreen"; 
import { useNavigate } from "react-router-dom";
import Wrapper from "./Wrapper";
import Boundary from '../../images/Legacy/boundary.png'
import confidence from '../../images/Legacy/confidence.png'
import feedback from '../../images/Legacy/feedback.png'
import salarytalk from '../../images/Legacy/salarytalk.png'
import communicating from '../../images/Legacy/communicating.png'
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

function ResourcesScreen({ onOpenSidebar,isSidebarOpen }) {
    const [selectedResource, setSelectedResource] = useState(null);
  const navigate = useNavigate(); // Use useNavigate for navigation

  const resources = [
    {
      title: "Boundaries",
      description:
        "Find useful videos, guides, and tools to help you navigate your personal and professional boundaries more effectively.",
      icon: Boundary,
    },
    {
      title: "Confidence",
      description:
        "Find useful videos, guides, and tools to help you navigate your personal and professional confidence more effectively.",
      icon: confidence,
    },
    {
      title: "Effective Feedback",
      description:
        "Find useful videos, guides, and tools to help you navigate your personal and professional feedback processes more effectively.",
      icon:feedback,
    },
    {
      title: "Salary Talk",
      description:
        "Find useful videos, guides, and tools to help you navigate your personal and professional salary negotiations more effectively.",
      icon: salarytalk,
    },
    {
      title: "Communicating Needs",
      description:
        "Find useful videos, guides, and tools to help you navigate your personal and professional communication needs more effectively.",
      icon: communicating,
    },
  ];
    
      return <>
      <Wrapper>
        {
          selectedResource ? (
            <ResourceDetailScreen
              resource={selectedResource}
              onBack={() => setSelectedResource(null)}
            />
          ) : (
        <div className="flex flex-col items-center w-full h-full p-6">
          {/* Header */}
          <div className="w-full flex items-center relative mb-4">
             <div className="w-full flex items-center py-4">
                   {/* Replace ChevronLeft with Menu icon */}
                   <button onClick={onOpenSidebar} className="p-2 z-50">
           {
             !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
           
           }
                   </button>
                   <h2 className="text-lg font-bold mx-auto">See Resources</h2>
                   <div className="w-6" /> {/* Spacer for alignment */}
                 </div>
          </div>
    
          {/* Resources List */}
          <div className="flex flex-col gap-4 z-10">
        {resources.map((resource, index) => (
          <div
            key={index}
            className="flex items-center bg-white rounded-lg shadow-md p-2 cursor-pointer"
            onClick={() => setSelectedResource(resource)}
          >
            {/* Icon */}
            <div className=" rounded-lg flex items-center justify-center bg-[#533884] w-[81px] h-[77px]">
              <img src={resource.icon} alt={resource.title} className="w-[81px] h-[77px]" />
            </div>

            {/* Content */}
            <div className="flex flex-col ml-4 flex-1">
              <h2 className=" font-bold text-[16px]">{resource.title}</h2>
              <p className=" text-gray-600 text-[8px]">{resource.description}</p>
              <div className="flex items-center gap-4 mt-2 text-sm " >
                <div className="flex items-center gap-1 cursor-pointer">
                  <Youtube size={16} className="text-[#E1B54A]"/> Videos
                </div>
                <div className="flex items-center gap-1 cursor-pointer">
                  <FileText size={16} className="text-[#E1B54A]"/> Guides & Links
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
        </div>
      )
        }
      </Wrapper>
      </>
}

export default ResourcesScreen;
