// components/Button.js
import React from "react";

export default function Button({ text, onClick }) {
  return (
    <button
      className="w-full h-[92px] bg-[#4E327A] text-white text-lg font-semibold rounded-lg shadow-md flex justify-center items-center"
      onClick={onClick}
    >
      {text}
    </button>
  );
}