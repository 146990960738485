import React,{useEffect,useState,useRef} from "react";
import { BrowserRouter as Router, Route, Routes,useNavigate } from "react-router-dom";
import "./App.css";

import Landing from "./pages/Landing/Landing";
import Taara from "./pages/Taara/Taara";
import About from "./pages/About/About";
import Blog from "./pages/Blog/Blog";
import FAQ from "./pages/FAQ/FAQ";
import Ambassadors from "./pages/Ambassadors/Ambassadors";
import Assessment from "./pages/Assessment/Assessment";
import BlogDetails from "./pages/BlogDetails/BlogDetails";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Profile from "./pages/Auth/Profile/Profile";
import Billing from "./pages/Auth/Billing/Billing";
import AuthCustomQuest from "./pages/Auth/CustomQuest/CustomQuest";
import ProtectedRoute from "./hoc/ProtectedRoute";
import AuthLanding from "./pages/Auth/AuthLanding/AuthLanding";
import VerifyUser from "./pages/VerifyUser/VerifyUser";
import { useSelector } from "react-redux";
import Pricing from "./pages/Pricing/Pricing";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import TermsOfService from "./pages/Terms/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import PitchTraining from "./pages/PitchTraining/PitchTraining";
import NotFound from "./components/NotFound/NotFound";
import CustomQuest from "./pages/CustomQuest/CustomQuest";
import TryTaara from "./pages/TryTaara/TryTaara";
import NewsLetter from "./pages/Newsletter/NewsLetter";
import { useDispatch } from "react-redux";
import { rehydrateAuthState } from "./store/authSlice";
import Legacy from "./pages/Legacy/Legacy";
import BillingPage from "./pages/Legacy/BillingPage";
import SupportScreen from "./pages/Legacy/SupportScreen";
import ProfilePage from "./pages/Legacy/ProfilePage";
import UnityView from "./pages/Legacy/UnityView";
import RoleplayScreen from "./pages/Legacy/RoleScreen";
import ResourcesScreen from "./pages/Legacy/Resources";
import HomeScreen from "./pages/Legacy/HomeScreen";
import Sidebar from "./pages/Legacy/components/Sidebar";
import SeeTutorial from "./pages/Legacy/SeeTutorial";
import QuickPrepScreen from "./pages/Legacy/QuickPrepScreen";
import TaskDetailsScreen from "./pages/Legacy/TaskDetailsScreen";
export default function App() {
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  // Function to open/close the sidebar
  const onOpenSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setIsSidebarOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  useEffect(() => {
    dispatch(rehydrateAuthState()); // Rehydrate auth state from localStorage
  }, [dispatch]);
    return (
      <Router>
        <div ref={sidebarRef}>
        <Sidebar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
        </div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/dashboard" element={<AuthLanding />} />
          <Route path="/taara" element={<Taara />} />
          <Route path="/about" element={<About />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/ambassador" element={<Ambassadors />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/pitch-training" element={<PitchTraining />} />
          <Route path="/custom-quest" element={<CustomQuest />} />
          <Route path="/trytaara" element={<TryTaara />} />
          <Route path="/news-letter" element={<NewsLetter />} />
          
          {/* Nesting Legacy routes inside /legacy-taara */}
          <Route path="/legacy-taara" element={<Legacy {...{setIsSidebarOpen,isSidebarOpen,sidebarRef}}/>} />

           
          <Route path="/legacy-taara/dashboard" element={<UnityView />} />
            
            <Route path="/legacy-taara/resources-screen" element={<ResourcesScreen {...{onOpenSidebar,isSidebarOpen}}/>} />
            <Route path="/legacy-taara/home-screen" element={<HomeScreen />} />
          <Route path="/billing-page" element={<BillingPage {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/support" element={<SupportScreen {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/profile-page" element={<ProfilePage {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/tutorial" element={<SeeTutorial {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/legacy-taara/roleplay-scenario" element={<RoleplayScreen {...{onOpenSidebar,isSidebarOpen}}/>} />

          <Route path="/quick-prep" element={<QuickPrepScreen {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/task-details" element={<TaskDetailsScreen {...{onOpenSidebar,isSidebarOpen}}/>} />
          <Route path="/verifyUser/:userId/:verificationId" element={<VerifyUser />} />
          <Route path="/resetPassword/:userId/:requestId" element={<ResetPassword />} />
  
          {/* Auth Routes */}
          <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
          <Route path="/billing" element={<ProtectedRoute element={<Billing />} />} />
          <Route path="/myquest" element={<ProtectedRoute element={<AuthCustomQuest />} />} />
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }
  
  // // Wrapper component to pass onBack prop
  // function RoleplayScreenWrapper() {
  //   const navigate = useNavigate();
  //   return <RoleplayScreen onBack={() => navigate(-1)} />;
  // }
