import React from "react";
import LazyVideo from "../../components/LazyVideo/LazyVideo";
import BgTop from "../../images/Legacy/topbg.svg";
import Bgbtlft from "../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../images/Legacy/btrgtbg.svg";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

export default function SeeTutorial({onOpenSidebar,isSidebarOpen}) {
  const navigate = useNavigate(); // Use useNavigate for navigation

  return (
    <div className="flex flex-col items-center w-full h-full  pt-[80px]">
      {/* 🔹 Back Button & Title */}
      <button onClick={onOpenSidebar} className="absolute left-0 p-2  z-50">
                     {
                       !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
                     
                     }
                     </button>
      <div className="w-full flex justify-center gap-[10px] items-center py-4">
         
        <h2 className="text-center font-bold legacy-taara text-[24px]">
          {"Welcome to Taara Quest!"}
        </h2>
      </div>

      <p className="text-center text-gray-600 text-sm mx-2  mt-2">
        Watch this short video to get a quick overview and start using Taara
        Quest with ease.
      </p>

      <div className="w-full mt-[30px] flex items-center justify-center">
        <LazyVideo
          className="TaaraIntroVideoArea"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
        />
      </div>

      <div className="flex flex-col  gap-4 mt-6 w-full max-w-xs z-10">
        <button className="h-[37px] bg-[#EB4176] text-white py-3 rounded-lg font-semibold text-center">
          Next
        </button>
        <button className="border border-[#EB4176] border-solid h-[37px] text-pink-500 py-3 rounded-lg font-semibold text-center">
          Skip
        </button>
      </div>

      <img
        src={BgTop}
        alt="Decorative Element"
        className="absolute -top-4 -left-3 w-16 rotate-[20deg]"
      />
      <img
        src={Bgbtlft}
        alt="Decorative Element"
        className="absolute bottom-0 left-0 w-32"
      />
      <img
        src={Bgbtrgt}
        alt="Decorative Element"
        className="absolute bottom-0 right-0 w-32"
      />
    </div>
  );
}
