// RoleplayScreen.js
import React, { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ChevronLeft, Search } from "lucide-react";
import Task from "./components/Task";
import styles from "../TryTaara/Unity.css";
import { fetchAllScenarios } from "../../services/scenarioService";
import Wrapper from "./Wrapper";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon
import TaskDetailsScreen from "./TaskDetailsScreen";
import QuickPrepScreen from "./QuickPrepScreen";
export default function RoleplayScreen({ onOpenSidebar,isSidebarOpen }) {
  const [scenarios, setScenarios] = useState([]);

  const [search, setSearch] = useState("");
  const [showQuickPrep, setShowQuickPrep] = useState(false);
  const navigate = useNavigate(); // Use useNavigate for navigation

  const filteredTasks = scenarios?.filter((task) =>
    task.title.toLowerCase().includes(search.toLowerCase())
  );
 const pullScenarios = async () => {
    try {
      const response = await fetchAllScenarios();
      const data = response.data?.data || [];
      setScenarios(data);
    } catch {
      setScenarios([]);
    }
  };

  useEffect(() => {
    pullScenarios();
  }, []);
 
const handleSelectTask=(task)=>{
  navigate("/task-details",{
    state: {
      userDataString: JSON.parse(localStorage.getItem('user')),
      selectedTask:task,
      sceneToLoad: "Roleplay", // Set scene to Roleplay
    },
  })
}
  return (
    <Wrapper>
    <div className="flex flex-col items-center w-full h-full relative z-10">

      <div className="w-full flex items-center px-6 mt-6 relative">
     
           <div className="w-full flex items-center py-4">
                <button onClick={onOpenSidebar} className="p-2 z-50">
        {
          !isSidebarOpen &&          <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
        
        }
                </button>
                <h2 className="text-lg font-bold mx-auto">{'Scenario Info'}</h2>
                <div className="w-6" /> {/* Spacer for alignment */}
              </div>
      </div>

      {/* Quick Prep or Task Details */}
      {showQuickPrep ? (
      <QuickPrepScreen />
      ) : (
        <>
          {/* Search Bar */}
          <div className="relative flex items-center w-[333px] mt-4 z-10">
            <div className="w-[70px] h-[51px] bg-[#4E327A] flex items-center justify-center rounded-l-full">
              <Search className="text-white" size={20} />
            </div>
            <input
              type="text"
              placeholder="Search a scenario"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full h-[49px] pl-4 text-lg border border-[#C7C7C7] rounded-r-full outline-none shadow-[0_0_0_1px_#C7C7C7]"
            />
          </div>

          {/* Scrollable Roleplay Task List */}
          <div className="grid grid-cols-2 gap-4 mt-6 w-full max-w-md max-h-[680px] overflow-y-auto scrollbar scrollable-container px-5 z-10">
            {filteredTasks?.length > 0 ? (
              filteredTasks?.map((task, index) => (
                <Task
                  key={index}
                  task={task}
                  onClick={()=>handleSelectTask(task)}
                />
              ))
            ) : (
              <p className="text-gray-500 text-lg mt-10">No scenarios found.</p>
            )}
          </div>
        </>
      )}
      
    </div>
    </Wrapper>
  );
}