import React, { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "../TryTaara/Unity.css";

export default function UnityView() {
  const location = useLocation();
  const { userDataString, selectedTask, sceneToLoad } = location.state || {};
  const navigate = useNavigate();

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "/mobileunitybuild.loader.js",
    dataUrl: 'https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/mobileunitybuild.data',
    codeUrl: 'https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/mobileunitybuild.wasm',
    frameworkUrl: "/mobileunitybuild.framework.js",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  useEffect(() => {
    const appconfig = {
      Main_URL: "https://staging.api.taara.quest/",
      UserID: userDataString?.id || "",
      ScenarioID: selectedTask?._id || "",
      SceneToLoad: sceneToLoad || "Roleplay",
    };

    if (isLoaded) {
      sendMessage("ReactUnityCommunicator", "InitializeAppConfig", JSON.stringify(appconfig));
    }
  }, [isLoaded, sendMessage, userDataString, selectedTask]);

  const handlePlayNewScenario = useCallback((tag) => {
    console.log(`[PlayNewScenario]:`, tag);
    navigate('/legacy-taara/roleplay-scenario')
  }, []);

  const handleExitUnity = useCallback((tag) => {
    console.log(`[ExitUnity]:`, tag);
    navigate('/legacy-taara/'); // Replace with the route you want to go back to
  }, []);
  
  useEffect(() => {
    if (addEventListener) {
      addEventListener("PlayNewScenario", handlePlayNewScenario);
      addEventListener("ExitUnity", handleExitUnity); // Add this line
    }
  
    return () => {
      if (removeEventListener) {
        removeEventListener("PlayNewScenario", handlePlayNewScenario);
        removeEventListener("ExitUnity", handleExitUnity); // Add this line
      }
    };
  }, [
    handlePlayNewScenario,
    handleExitUnity,
    addEventListener,
    removeEventListener,
  ]);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-white">
      <script src='./InworldMicrophone.js'></script>
      <div
        className="relative w-full h-full md:w-[390px] md:h-[844px] bg-white flex justify-center items-center overflow-hidden"
        style={{
          maxHeight: '100vh',
          maxWidth: '100vw',
          // boxShadow: '0 0 20px rgb(255, 255, 255)'
        }}
      >
        {!isLoaded && (
          <div className="absolute z-10 flex justify-center items-center w-full h-full">
            <div className={styles.loaderContainer}>
              <div className={styles.spinner}></div>
              <div className={styles.loadingBar}>
                <div
                  className={styles.loadingBarFill}
                  style={{ width: `${loadingProgression * 100}%` }}
                />
              </div>
              <div className="w-full h-[300px] flex justify-center items-center">
                <div className="w-24 h-24 flex justify-center items-center border-2 border-black rounded-full">
                  <p className={styles.loadingText}>
                    {Math.round(loadingProgression * 100)}%
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <Unity
          unityProvider={unityProvider}
          style={{
            display: isLoaded ? "block" : "none",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
}