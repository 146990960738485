import React, { useState } from "react";
import BgTop from "../../images/Legacy/topbg.svg";
import Bgbtlft from "../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../images/Legacy/btrgtbg.svg";
import { useNavigate } from "react-router-dom";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon
import axios from "axios";

export default function SupportScreen({ onOpenSidebar, isSidebarOpen }) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!title || !description) {
      setErrorMessage("Please fill in all required fields.");
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("email", "support@taara.quest");
    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await axios.post(
        "https://api.elasticemail.com/v2/email/send",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setTitle("");
      setDescription("");
      setFile(null);
      if (response.data.success) {
        setErrorMessage("Your request has been submitted successfully!");
      } else {
        setErrorMessage(response.data.error);
      }
    } catch (error) {
      setErrorMessage("Something went wrong! Please try again.");
    }

    setIsLoading(false);
  };

  return (
    <div className="flex flex-col items-center w-full h-full px-4">
      {/* Back Button & Title */}
      <div className="w-full flex items-center py-4">
        {/* Replace ChevronLeft with Menu icon */}
        <button onClick={onOpenSidebar} className="p-2 z-50">
          {!isSidebarOpen && (
            <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
          )}
        </button>
        <h2 className="text-lg font-bold mx-auto">Support</h2>
        <div className="w-6" /> {/* Spacer for alignment */}
      </div>

      {/* Description */}

      {/* Form Section */}
      <div className="bg-white flex flex-col gap-[15px] shadow-md rounded-lg p-6 w-full max-w-md mt-6 z-10">
        <p className="text-center text-gray-500 text-sm px-6 mt-2">
          We know things don't always go as planned. If you are running into
          issues—whether it's a bug, a billing hiccup, or something in the
          roleplay that doesn't feel right—let us know.
        </p>

        <label className="block text-sm font-semibold text-gray-700">
          Categories
        </label>
        <select
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="w-full pt-2 pb-2 mt-1 border border-gray-300 border-solid rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500 bg-white"
        >
          <option value="">Select an option</option>
          <option value="Technical Issue">Technical Issue</option>
          <option value="Billing & Subscription">Billing & Subscription</option>
          <option value="Roleplay & Content">Roleplay & Content</option>
          <option value="Others">Others</option>
        </select>

        <label className="block text-sm font-semibold text-gray-700 mt-4">
          Explain The Problem
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 mt-1 border border-gray-300 rounded-md h-24 focus:outline-none focus:ring-2 focus:ring-purple-500"
        />

        {/* File Upload Section */}
        <label className="block text-sm font-semibold text-gray-700 mt-4">
          Attach A File
        </label>
        <div className="border border-dashed border-gray-400 rounded-lg p-4 text-center cursor-pointer h-[130px]">
          <input
            type="file"
            onChange={handleFileChange}
            className="hidden"
            id="fileInput"
          />
          <label htmlFor="fileInput" className="block cursor-pointer">
            {file ? (
              <p className="text-sm text-gray-700">{file.name}</p>
            ) : (
              <p className="text-sm text-gray-500 font-bold">
                Drag & drop files or{" "}
                <span className="text-[#E1B54A] font-semibold">Browse</span>
                <br />
                <span className="text-[10px]">
                  Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word,
                  PPT
                </span>
              </p>
            )}
          </label>
        </div>

        {/* Submit Button */}
        <button
          onClick={handleSubmit}
          disabled={isLoading}
          className="w-full bg-[#533884] text-white py-3 rounded-lg font-semibold text-center mt-6"
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>

        {/* Error Message */}
        {errorMessage && (
          <p className="text-red-500 text-center mt-2">{errorMessage}</p>
        )}
      </div>
      <img
        src={BgTop}
        alt="Decorative Element"
        className="absolute -top-4 -left-3 w-16 rotate-[20deg] z-0"
      />
      <img
        src={Bgbtlft}
        alt="Decorative Element"
        className="absolute bottom-0 left-0 w-32 z-0"
      />
      <img
        src={Bgbtrgt}
        alt="Decorative Element"
        className="absolute bottom-0 right-0 w-32 z-0"
      />
    </div>
  );
}
