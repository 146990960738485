import React from "react";

const InputText = React.forwardRef(({ onChange, value, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      type="text"
      className="w-full !border-2 !border-primaryBlue rounded-full text-center h-12 font-nohemi"
      onChange={onChange}
      value={value}
      {...rest}
      style={{ border: "2px solid #533884" }}
    />
  );
});

export default InputText;
