import React from "react";

export default function SidebarItem({ text, icon, active, inactive, onClick }) {
  return (
    <div
      className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer 
        ${inactive ? "bg-gray-300 text-gray-500 cursor-not-allowed" : active ? "bg-[#d0c8dd] text-purple-800 font-semibold" : "hover:bg-[#d0c8dd] text-[black]"}
      `}
      onClick={!inactive ? onClick : undefined} // Disable onClick if inactive
    >
      <span>{icon}</span>
      {text}
    </div>
  );
}
