import React, { useEffect } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import { useForm } from "react-hook-form";
import TextInput from "../../components/formInputs/TextInput";
import { Link, useNavigate } from "react-router-dom";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import roundLogo from "../../images/round-logo.svg";
import { encryptData } from "../../utility/encryption";
import useMutationRateLimitingAPI from "../../hooks/useMutationRateLimitingAPI";
import Spinner from "../../components/Spinner/Spinner";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/authSlice";

const URL = `${process.env.REACT_APP_BACKEND}/auth/login`;

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const onError = (error) => {
    if (error?.status === 403) {
      return toast.error("Invalid credentials");
    }
    const errorResponse = error?.response?.data;
    if (errorResponse) {
      return toast.error(errorResponse?.message);
    }
    return toast.error("Something went wrong");
  };
  const onSuccess = (response) => {
    const {
      data: {
        data: { token, userData },
      },
    } = response;
    dispatch(loginSuccess({ token, user: userData }));
    localStorage.setItem('token', token);
    navigate("/dashboard");
  };
  const { mutate, isPending } = useMutationRateLimitingAPI({
    limit: 5,
    timeWindow: 1000,
    onSuccess,
    onError,
  });
  const onSubmit = (data) => {
    const { email, password } = data;
    const userData = {
      email,
      password,
      currentTime: new Date(),
    };

    const encryptedPayload = encryptData(JSON.stringify(userData));
    const payload = {
      token: encryptedPayload,
    };
    mutate({ url: URL, data: payload });
  };
  return (
    <NonProtectedLayout>
      <SpaceWrapper>
        <div className="px-[170px] mobile:px-[0px] py-16 text-white">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-9 mobile:gap-6 bg-primaryBlue relative rounded-[20px] px-[300px] mobile:px-10 pt-[153px] mobile:pt-16 pb-7"
          >
            <h2 className="text-center text-[32px] mobile:text-[24px] font-audioWide">
              LOG IN
            </h2>
            <div className="flex flex-col gap-6">
              <TextInput
                type="email"
                name="email"
                placeholder="Email Address"
                register={register}
                errors={errors}
                isRequired={true}
                validateInput
                pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
              />
              <TextInput
                type="password"
                name="password"
                placeholder="Password"
                register={register}
                errors={errors}
                isRequired={true}
              />
            </div>
            <div className="flex flex-col gap-6 mobile:gap-3 items-center justify-center">
              <button
                type="submit"
                disabled={isPending}
                className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] w-[130px] px-5 hover:scale-110 transition-transform disabled:bg-opacity-70 disabled:cursor-not-allowed disabled:scale-100"
              >
                {isPending ? <Spinner /> : "Login"}
              </button>
              <div className="flex flex-col gap-2">
                <p className="text-md text-sm text-center">
                  Don’t have an account yet?{" "}
                  <Link to="/signup" className="underline">
                    Sign up
                  </Link>{" "}
                  instead.
                </p>
                <p className="text-md text-sm text-center">
                  <Link to="/forgot-password" className="underline">
                    Forgot password?
                  </Link>
                </p>
              </div>
            </div>
            <div className="w-[180px] h-[180px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
              <LazyImageObjectFit
                src={roundLogo}
                alt="Taara Quest Logo"
                className="object-cover"
                bg="none"
              />
            </div>
          </form>
        </div>
        <Toaster />
      </SpaceWrapper>
    </NonProtectedLayout>
  );
};

export default Login;
