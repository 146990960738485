import { useState } from "react";
import BgTop from "../../images/Legacy/topbg.svg";
import Bgbtlft from "../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../images/Legacy/btrgtbg.svg";
import { useNavigate } from "react-router-dom";
import { BookOpen, FileText, Youtube } from "lucide-react";
import Bgwrapper from "./components/Bgwrapper";
import Wrapper from "./Wrapper";
import Menu from "../../images/Legacy/menu.svg"; // Import the Menu icon

export default function ProfilePage({ onOpenSidebar, isSidebarOpen }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("Luke Profans");
  const [password, setPassword] = useState("**********");
  const [email] = useState("luke@mockmail.com");
  const [country] = useState("Austria");
  const navigate = useNavigate(); // Use useNavigate for navigation

  return (
    <Wrapper>
      <div className="flex items-start justify-center min-h-screen bg-white pt-[80px]">
        <div className="">
          {/* Replace ChevronLeft with Menu icon */}
          {
            !isSidebarOpen &&   <button onClick={onOpenSidebar} className="absolute left-0 p-2 z-50">
            <img src={Menu} alt="Menu Icon" className="w-6 h-6" />
          </button>
          }
        
          <h2 className="text-center text-[27px] font-semibold text-gray-800 mb-[50px]">
            Profile
          </h2>
          {!isEditing ? (
            <div className="relative p-6 bg-white shadow-lg rounded-lg z-10">
              <div className="mt-4 space-y-2 p-4">
                <label className="block text-sm font-semibold text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  value={name}
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />

                <label className="block text-sm font-semibold text-gray-700 pt-[15px]">
                  Email
                </label>
                <input
                  type="text"
                  value={email}
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />

                <label className="block text-sm font-semibold text-gray-700 pt-[15px]">
                  Password
                </label>
                <input
                  type="text"
                  value={password}
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />

                <label className="block text-sm font-semibold text-gray-700 pt-[15px]">
                  Country
                </label>
                <input
                  type="text"
                  value={country}
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="w-1/2 max-w-sm bg-[#533884] text-white py-2 mt-4 rounded-lg font-nohemi text-center"
                  onClick={() => setIsEditing(true)}
                >
                  Edit Profile
                </button>
              </div>
            </div>
          ) : (
            <div className="relative p-6 bg-white shadow-lg rounded-lg z-50">
              <div className="space-y-2 pr-4">
                <label className="block font-medium">Change Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <div className="flex space-x-2 pt-2">
                  <button
                    className="flex-1 border bg-[#EB4176] text-white h-[33px] rounded-lg font-nohemi text-center w-1/2"
                    onClick={() => setIsEditing(false)}
                  >
                    Save
                  </button>
                  <button
                    className="border border-[#EB4176] border-solid h-[33px] text-pink-500 rounded-lg font-semibold text-center w-1/2"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                </div>
                <label className="block font-medium mt-4 pt-2">
                  Current Password
                </label>
                <input
                  type="password"
                  placeholder="Current password"
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <label className="block font-medium mt-4 pt-2">
                  New Password
                </label>

                <input
                  type="password"
                  placeholder="New password"
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
                <label className="block font-medium mt-4 pt-2">
                  Confirm Password
                </label>

                <input
                  type="password"
                  placeholder="Confirm password"
                  className="w-full p-2 border border-gray-300 border-solid rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />

                <div className="flex space-x-2 mt-2 pt-2">
                  <button
                    className="border bg-[#EB4176] text-white h-[33px] rounded-lg font-nohemi text-center w-1/2"
                    onClick={() => setIsEditing(false)}
                  >
                    Save
                  </button>
                  <button
                    className="border border-[#EB4176] border-solid h-[33px] text-pink-500 rounded-lg font-semibold text-center w-1/2"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
}