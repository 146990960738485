// Legacy.js
import { useState, useRef, useEffect ,useCallback} from "react";
import TaaraLogo from "../../images/logo.svg";
import BgTop from "../../images/Legacy/topbg.svg";
import Bgbtlft from "../../images/Legacy/btlftbg.svg";
import Bgbtrgt from "../../images/Legacy/btrgtbg.svg";
import Menu from "../../images/Legacy/menu.svg";
import Telegram from "../../images/Legacy/telegram.svg";
import "./LegacyStyle.css";
import HomeScreen from "./HomeScreen";
import RoleplayScreen from "./RoleScreen";
import Sidebar from "./components/Sidebar";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useDispatch, useSelector } from "react-redux";
import Bgwrapper from "./components/Bgwrapper";
export default function Legacy({setIsSidebarOpen,isSidebarOpen,sidebarRef}) {

  const [showUnity, setShowUnity] = useState(false); // State to control Unity visibility
 

  return (
    <div className="w-[390px] h-[844px] mx-auto flex flex-col items-center bg-white relative overflow-hidden">
     
        <HomeScreen
        isSidebarOpen={isSidebarOpen}
          onOpenSidebar={() => setIsSidebarOpen(true)}
        />
      

      {/* {isSidebarOpen && (
        <div ref={sidebarRef}>
          <Sidebar onClose={() => setIsSidebarOpen(false)} />
        </div>
      )} */}

      {/* Decorative Background Elements */}
      {!showUnity && <>
      <Bgwrapper /></>}
   
    </div>
  );
}